<template>
  <div style="width: 1200px;margin: 0 auto;background-color: #F4F6FC">
    <banner :bannerPath="bannerPath" :imageList="imageList">
      <div slot="content" class="region-header">
        <div style="margin-top: -30px">
          <span class="title">道普云</span>
          <span class="title-red">专区</span>
        </div>
        <div class="sub-header">
          <div>
            <span class="sub-title"><i class="el-icon-star-on ufc-6"></i>安全测试</span>
            <span class="sub-title"><i class="el-icon-star-on ufc-6"></i>软件测试</span>
            <span class="sub-title"><i class="el-icon-star-on ufc-6"></i>数据安全</span>
          </div>
          <div class="mt-2">
            <span class="sub-title"><i class="el-icon-star-on ufc-6"></i>网络安全</span>
            <span class="sub-title"><i class="el-icon-star-on ufc-6"></i>培训咨询</span>
          </div>
        </div>
      </div>
    </banner>
    <div class="tac mt-5 mb-3" style="background-color: white;margin-top: -1px">
      <img src="~@/assets/img/prefecture/topremen.png" height="64px" style="margin-top: 40px">
      <service :cfItemList="cfItemList" :service-list="serviceList" @getDataList=getDataList
               :page-index="pageIndex" :page-size="pageSize" :total-count="totalCount"></service>
      <el-divider></el-divider>
      <div class="tac mt-5 mb-3">
        <img src="~@/assets/img/prefecture/topsupport.png" height="64px">
      </div>
      <div class="introduction">

      </div>
      <div style="line-height: 180%;margin-bottom: 20px;margin-top: -120px;padding: 10px 20px 10px 20px;text-align:left;">
        <el-row>
          <el-col :span="14">
            <div style="text-align: center">
              <img src="~@/assets/img/prefecture/toplogo.png" height="64px">
            </div>

            <div style="text-indent: 40px;line-height: 180%;margin-bottom: 10px;margin-top: 10px;padding: 10px 20px 10px 20px;text-align:left;">
                <span>
                   道普云（山东）智能科技有限公司， 是由济南市市中区政府、道普信息技术有限公司（山东省软件评测中心）、英国Micro Focus公司三方战略合作成立的一站式云测试平台，
                  是目前国内屈指可数的可以同时提供性能测试、代码测试、Web应用安全测试、移动应用安全测试、移动应用加固、软件成分分析、验收测试、渗透测试、
                  功能自动化测试等工具和服务的云测试平台。
                </span>
            </div>
            <div style="text-indent: 40px;line-height: 180%;margin-bottom: 20px;margin-top: 10px;padding: 10px 20px 10px 20px;text-align:left;">
              <span>
                  道普云借助Micro Focus、CAST、爱加密、龙测等行业领军工具厂商技术实力，依托道普信息技术有限公司（山东省软件测评中心）二十余年的丰富测试经验，
                  为客户提供专业化的测试服务和测试工具。致力于通过行业领先的测试工具产品和测试服务解决方案，提高中国软件产品质量。
                </span>
            </div>
          </el-col>
          <el-col :span="10">
            <img src="~@/assets/img/prefecture/topabout.jpg" height="300px" style="margin-top: 80px">
          </el-col>
        </el-row>
      </div>
      <div style="line-height: 180%;margin-top: 10px;padding: 10px 20px 10px 20px;text-align:left;color: #FFC000;font-size: 20px">
        <img src="~@/assets/img/prefecture/topyoushi.png" height="64px">
      </div>
      <div>
        <img src="~@/assets/img/prefecture/topcontent.png" width="1100px">
      </div>
      <div style="line-height: 180%;margin-top: 10px;padding: 10px 20px 10px 20px;text-align:left;color: #FFC000;font-size: 20px">
        <img src="~@/assets/img/prefecture/topcustomer.png" height="64px">
      </div>
      <div>
        <img src="~@/assets/img/prefecture/topcuscontent.png" width="1100px">
      </div>
    </div>

<!--    <div class="jianjie p-5">-->
<!--      <div class="tac fwb" style="font-size: 50px">-->
<!--        公司简介-->
<!--      </div>-->
<!--      <div style="text-indent: 40px;line-height: 180%;margin-bottom: 20px;margin-top: 40px">-->
<!--                <span>-->
<!--       道普信息技术有限公司是一家专注于信息化第三方保障服务的机构，是国内第一家提出信息化风险全面第三方保障理念的机构，致力于帮助客户全面控制信息化风险。公司总部位于泉城济南，-->
<!--在华南、华北、华东、华中、西南、西北等多省市设有分部。公司作为山东省计算中心（国家超级计算济南中心）在软件工程、信息安全等多领域科研成果应用与服务载体，-->
<!--以多年的科研成果积累和雄厚的技术能力为基础，面向社会提供信息化战略方向风险、项目建设过程风险、信息安全风险等相关的检测、咨询服务，致力于让用户的信息化更简单更安全。-->
<!--                </span>-->
<!--      </div>-->
<!--      <div style="text-indent: 40px;line-height: 180%;margin-bottom: 20px">-->
<!--                <span>-->
<!--       公司由山东省计算中心（国家超级计算济南中心）所属山东省软件评测中心于 2016 年通过科研成果转化成立。2002 年，软件评测中心通过中国实验室合格评定委员会的认可，-->
<!--成为国内第一批综合性信息化检测咨询服务机构，并先后获得经信、科技、公安、住建、交通、保密、密码等多个政府主管部门的授权或认可。目前，公司已完成计算机软件、-->
<!--智能系统、信息安全、涉密工程、两化融合等检测、咨询服务项目数万余项，协助软件企业、信息化建设单位在软件质量、信息安全、智能系统等方面获得较大的提升。-->
<!--                </span>-->
<!--      </div>-->
<!--      <div style="text-indent: 40px;line-height: 180%;margin-bottom: 20px">-->
<!--                <span>-->
<!--        为充分保证检验、检测工作的客观性、独立性和公正性，保证本公司检验、检测工作符合相关法律、法规要求，道普信息技术有限公司承诺严格执行国家、行业有关的法律法规、-->
<!--检验检测标准和技术规范，服从各级政府质量监督部门的监管，保证检验检测结果的准确性、客观性、公正性，不出具虚假检测报告。-->
<!--                </span>-->
<!--      </div>-->
<!--    </div>-->
<!--    <div class="zizhi p-5">-->
<!--      <div class="fwb tac" style="font-size: 50px">-->
<!--        公司资质-->
<!--      </div>-->
<!--      <div style="margin-top: 40px">-->
<!--        <el-row style="margin-bottom: 40px">-->
<!--          <el-col :span="5">-->
<!--            <span class="dian">●</span>-->
<!--            <span style="font-size: 20px;margin-left: 20px">信息系统监理单位</span>-->
<!--          </el-col>-->
<!--          <el-col :span="5">-->
<!--            <span class="dian">●</span>-->
<!--            <span style="font-size: 20px;margin-left: 20px">质量管理体系资质</span>-->
<!--          </el-col>-->
<!--          <el-col :span="5">-->
<!--            <span class="dian">●</span>-->
<!--            <span style="font-size: 20px;margin-left: 20px">信息技术服务管理体系资质</span>-->
<!--          </el-col>-->
<!--          <el-col :span="5">-->
<!--            <span class="dian">●</span>-->
<!--            <span style="font-size: 20px;margin-left: 20px">信息安全管理体系资质</span>-->
<!--          </el-col>-->
<!--          <el-col :span="4">-->
<!--            <span class="dian">●</span>-->
<!--            <span style="font-size: 20px;margin-left: 20px">ISCCC资质</span>-->
<!--          </el-col>-->
<!--        </el-row>-->
<!--        <el-row style="margin-bottom: 40px">-->
<!--          <el-col :span="5">-->
<!--            <span class="dian">●</span>-->
<!--            <span style="font-size: 20px;margin-left: 20px">涉密信息系统集成资质</span>-->
<!--          </el-col>-->
<!--          <el-col :span="5">-->
<!--            <span class="dian">●</span>-->
<!--            <span style="font-size: 20px;margin-left: 20px">检验机构认可资质</span>-->
<!--          </el-col>-->
<!--          <el-col :span="5">-->
<!--            <span class="dian">●</span>-->
<!--            <span style="font-size: 20px;margin-left: 20px">CMA资质</span>-->
<!--          </el-col>-->
<!--          <el-col :span="5">-->
<!--            <span class="dian">●</span>-->
<!--            <span style="font-size: 20px;margin-left: 20px">实验室认可资质</span>-->
<!--          </el-col>-->
<!--          <el-col :span="4">-->
<!--            <span class="dian">●</span>-->
<!--            <span style="font-size: 20px;margin-left: 20px">软件企业资质</span>-->
<!--          </el-col>-->
<!--        </el-row>-->
<!--        <el-row>-->
<!--          <el-col :span="10">-->
<!--            <span class="dian">●</span>-->
<!--            <span style="font-size: 20px;margin-left: 20px">国家信息安全测评授权培训机构资质</span>-->
<!--          </el-col>-->
<!--          <el-col :span="5">-->
<!--            <span class="dian">●</span>-->
<!--            <span style="font-size: 20px;margin-left: 20px">中关村信息安全测评联盟会员</span>-->
<!--          </el-col>-->
<!--          <el-col :span="9">-->
<!--            <span class="dian">●</span>-->
<!--            <span style="font-size: 20px;margin-left: 20px">网络安全等级保护（DJCP）资质</span>-->
<!--          </el-col>-->
<!--        </el-row>-->
<!--      </div>-->
<!--    </div>-->

  </div>
</template>

<script>
import Banner from './component/banner'
import Service from './component/service'

export default {
  name: 'prefecture',
  components: {
    Banner,
    Service
  },
  data() {
    return {
      bannerPath: require('../../../assets/img/prefecture/topBanner3.png'),
      imageList: [
        {
          index: 1,
          image: require('../../../assets/img/index/rjpc-banner.png')
        },
        {
          index: 2,
          image: require('../../../assets/img/index/rjpc-banner.png')
        },
        {
          index: 3,
          image: require('../../../assets/img/index/rjpc-banner.png')
        },
        {
          index: 4,
          image: require('../../../assets/img/index/rjpc-banner.png')
        },
        {
          index: 5,
          image: require('../../../assets/img/index/rjpc-banner.png')
        },
        {
          index: 6,
          image: require('../../../assets/img/index/rjpc-banner.png')
        }
      ],
      serviceList: [],
      cfItemList: [
        {
          id: 0,
          icon: 'icon-jiance iconfont',
          name: '安全测试'
        },
        {
          id: 1,
          icon: 'icon-jiance iconfont',
          name: '质量测试'
        },
        {
          id: 2,
          icon: 'icon-jiance iconfont',
          name: '软件安全'
        },
        {
          id: 3,
          icon: 'icon-jiance iconfont',
          name: '网络安全'
        },
      ],
      pageIndex: 1,
      pageSize: 10,
      totalCount: 0
    }
  },
  created() {
    this.prefectureKey = this.$route.params.prefectureKey
    this.prefectureName = this.$route.params.prefectureName
    this.getCfItemList()
    this.getDataList(null, 1, 8)
  },
  methods: {
    getCfItemList() {
      this.$http({
        url: this.$http.adornUrl(this.$api.PUB.CF_ITEM),
        methods: 'get',
        params: this.$http.adornParams({
          enterpriseId: '614d0b00617e486a839673531b454cea'
        })
      }).then(({data}) => {
        if (data && data.code === 0) {
          this.cfItemList = data.list
          this.cfItemList = [{
            itemCode: null,
            itemName: '全部'
          }, ...this.cfItemList]
        }
      })
    },

    getDataList(itemCode, pageIndex, pageSize) {
      this.$http({
        url: this.$http.adornUrl(this.$api.PUB.TOP_PRODUCT),
        methods: 'get',
        params: this.$http.adornParams({
          page: pageIndex,
          limit: pageSize,
          itemCode: itemCode || null,
          enterpriseId: '614d0b00617e486a839673531b454cea'
        })
      }).then(({data}) => {
        if (data && data.code === 0) {
          this.serviceList = data.page.list
          this.pageIndex = pageIndex
          this.pageSize = data.page.pageSize
          this.totalCount = data.page.totalCount


          // if (name === 'first') {
          //     this.tabs = []
          //     this.dataList.forEach(item => {
          //         if(item.itemCode === '800302' || item.itemCode === '800401'){
          //             this.tabs.push(item)
          //         }
          //     })
          //     this.totalCount = this.tabs.length
          //     this.totalPage = this.tabs.length
          //     var start = (this.pageIndex-1) * this.pageSize
          //     var end = this.tabs.length > this.pageIndex * this.pageSize ? this.pageIndex * this.pageSize : this.tabs.length
          //     this.tabs = this.tabs.slice(start, end)
          // }
          // if (name === 'second') {
          //     this.tabs = []
          //     this.dataList.forEach(item => {
          //         if(item.itemCode === '500501' || item.itemCode === '500502' || item.itemCode === '500503'){
          //             this.tabs.push(item)
          //         }
          //     })
          //     this.totalCount = this.tabs.length
          //     this.totalPage = this.tabs.length
          //     var start = (this.pageIndex-1) * this.pageSize
          //     var end = this.tabs.length > this.pageIndex * this.pageSize ? this.pageIndex * this.pageSize : this.tabs.length
          //
          //     this.tabs = this.tabs.slice(start, end)
          // }
          // if (name === 'third') {
          //     this.tabs = []
          //     this.dataList.forEach(item => {
          //         if(item.itemCode === '800501' || item.itemCode === '800502'){
          //             this.tabs.push(item)
          //         }
          //     })
          //     this.totalCount = this.tabs.length
          //     this.totalPage = this.tabs.length
          //     var start = (this.pageIndex-1) * this.pageSize
          //     var end = this.tabs.length > this.pageIndex * this.pageSize ? this.pageIndex * this.pageSize : this.tabs.length
          //     this.tabs = this.tabs.slice(start, end)
          // }
          // if (name === 'fourth') {
          //     this.tabs = []
          //     this.dataList.forEach(item => {
          //         if(item.itemCode === '800301' || item.itemCode === '800303'){
          //             this.tabs.push(item)
          //         }
          //     })
          //     this.totalCount = this.tabs.length
          //     this.totalPage = this.tabs.length
          //     var start = (this.pageIndex-1) * this.pageSize
          //     var end = this.tabs.length > this.pageIndex * this.pageSize ? this.pageIndex * this.pageSize : this.tabs.length
          //     this.tabs = this.tabs.slice(start, end)
          // }
          // if (name === 'five') {
          //     this.tabs = []
          //     this.dataList.forEach(item => {
          //         if(item.itemCode === '500109' || item.itemCode === '800303'){
          //             this.tabs.push(item)
          //         }
          //     })
          //     this.totalCount = this.tabs.length
          //     this.totalPage = this.tabs.length
          //     var start = (this.pageIndex-1) * this.pageSize
          //     var end = this.tabs.length > this.pageIndex * this.pageSize ? this.pageIndex * this.pageSize : this.tabs.length
          //     this.tabs = this.tabs.slice(start, end)
          // }
        } else {
          this.dataList = []
          this.totalCount = 0
          this.totalPage = 1
        }
      })
    }
  }
}
</script>

<style scoped>
.jianjie {
  background-image: url("../../../assets/img/prefecture/topIntroduction.png");
  background-size: cover;
  height: 550px;
}

.zizhi {
  background-image: url("../../../assets/img/prefecture/topBackground.png");
  background-size: cover;
  height: 550px;
  padding: 80px 80px 40px 80px;
}

.dian {
  font-size: 30px;
  color: #ff8d0d;
  text-shadow: 2px 2px black;
}
  .introduction {
    background-image: url("../../../assets/img/prefecture/topintro.jpg");
    background-size: cover;
    width: 1100px;
    height: 130px;
    margin-left: 20px;
  }
</style>
